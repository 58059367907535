import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import consumer from "../../channels/consumer";

// Connects to data-controller='messages--post'
export default class extends Controller {
  static targets = ['messageList', 'input'];

  connect() {
    this.companyId = this.element.dataset.companyId;
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const channelParams = { channel: 'ChatroomChannel', company_id: this.companyId };

    // Action Cableでチャットルームに接続
    this.channel = consumer.subscriptions.create(channelParams, {
      received: (data) => {
        if (data.notification) {
          this.fetchMessage(data.message_id);
        }
      }
    });
  }

  submit(event) {
    event.preventDefault();

    // フォームのデータを取得
    const formData = new FormData(event.target);

    // フォーム送信
    fetch(event.target.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(response => response.json())
    .then(data => {
      this.sendMessage(data.message_id);
      this.inputTarget.value = ''
    })
    .catch(error => console.error('Error:', error));
  }

  sendMessage(messageId) {
    this.channel.perform('speak', { company_id: this.companyId, message_id: messageId });
  }

  fetchMessage(messageId) {
    fetch(`/internals/messages/${messageId}?company_id=${this.companyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
      this.intervalForMessage(messageId);
    })
    .catch(error => console.error('Error:', error));
  }

  intervalForMessage(messageId) {
    // 0.1秒ごとに要素を再取得
    let attempts = 0; // 試行回数をカウントする変数
    const intervalId = setInterval(() => {
      const message = document.getElementById(messageId);

      if (message) {
        this.scrollToMessage(message);

        // 取得成功したら、intervalをクリア
        clearInterval(intervalId);
      }
      // 最大試行回数に達した場合、intervalをクリア
      if (++attempts >= 5) {
        clearInterval(intervalId);
      }
    }, 100); // 100ミリ秒ごとに実行
  }

  scrollToMessage(message) {
    const messageList = this.messageListTarget;

    if (messageList && message) {
      // 既読メッセージの位置を取得
      const messageRect = message.getBoundingClientRect();
      const messageListRect = messageList.getBoundingClientRect();

      // スクロール位置を計算して、messageListの最後に位置するようにする
      const scrollToPosition = messageRect.top - messageListRect.top + messageList.scrollTop + messageRect.height - messageListRect.height;

      // スクロール位置を設定
      messageList.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });
    }
  }
}
