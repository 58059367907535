import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='orders-preview'
export default class extends Controller {
  static targets = ["preview"]

  show(event) {
    event.preventDefault()
    const imageUrl = event.currentTarget.getAttribute("data-url")
    this.previewTarget.querySelector("img").src = imageUrl
  }
}
