import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import consumer from "../../channels/consumer";

// Connects to data-controller='scroll'
export default class extends Controller {
  static targets = ['messageList', 'message', 'scrollPosition', 'prev', 'next'];

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.companyId = this.element.dataset.companyId;
    this.lastReadMessageId = this.messageListTarget.dataset.lastReadMessageId;

    // 既読メッセージの最後までスクロール
    this.scrollToLastReadMessage();
    this.fixPosition(true);

    // スクロールメッセージ取得イベント
    this.observePrevElement();
    this.observeNextElement();
  }

  disconnect() {
    // observerの監視を解除する
    this.disconnectObserver();
  }

  observePrevElement() {
    this.observePrev = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.fetchMessages(this.firstMessageId, true);
        }
      });
    }, {
      root: null,
      threshold: 1
    });

    this.observePrev.observe(this.prevTarget);
  }

  observeNextElement() {
    this.observeNext = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.fetchMessages(this.lastMessageId);
        }
      });
    }, {
      root: null,
      threshold: 1
    });

    this.observeNext.observe(this.nextTarget);
  }

  disconnectObserver() {
    if (this.observePrev) {
      this.observePrev.disconnect();
    }
    if (this.observeNext) {
      this.observeNext.disconnect();
    }
  }

  fetchMessages(messageId, prev = false) {
    const params = new URLSearchParams({
      company_id: this.companyId,
      message_id: messageId,
      prev: prev
    });

    fetch(`/internals/messages?${params.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
      this.fixPosition(prev);
    })
    .catch(error => console.error('Error:', error));
  }

  scrollToLastReadMessage() {
    const messageList = this.messageListTarget;

    if (messageList && this.lastReadMessageId) {
      // lastReadMessageId に基づいてメッセージアイテムを直接取得
      const lastReadMessage = document.getElementById(this.lastReadMessageId);

      // 既読メッセージの位置を取得
      const lastReadMessageRect = lastReadMessage.getBoundingClientRect();
      const messageListRect = messageList.getBoundingClientRect();

      // メッセージリストの上端から、スクロールしたいメッセージまでの相対位置
      const messageOffset = lastReadMessageRect.top - messageListRect.top;
      // 現在のスクロール位置（リストの一番上からのスクロール量）
      const scrollAdjustment = messageList.scrollTop;
      // メッセージが画面内に完全に表示されるようにするための高さ調整
      const heightAdjustment = lastReadMessageRect.height - messageListRect.height;
      // メッセージがちょうど画面内に収まる位置を計算
      const scrollToPosition = messageOffset + scrollAdjustment + heightAdjustment;

      // スクロール位置を設定
      messageList.scrollTop = scrollToPosition;
    }
  }

  fixPosition(prev = false) {
    const scrollPosition = this.scrollPositionTarget;
    const messageList = this.messageListTarget;

    if (scrollPosition && messageList) {
      const scrollPositionRect = scrollPosition.getBoundingClientRect();
      const messageListRect = messageList.getBoundingClientRect();

      let offset;
      if (prev) {
        // prevTargetがscrollPosition上部に隠れるように設定
        offset = messageListRect.top - scrollPositionRect.top;
      } else {
        // nextTargetがscrollPosition下部に隠れるように設定
        offset = messageListRect.bottom - scrollPositionRect.bottom;
      }

      // スクロール位置を設定
      scrollPosition.scrollTo({
        top: scrollPosition.scrollTop + offset,
        behavior: 'smooth'
      });
    }
  }

  get firstMessageId() {
    return this.messageTargets[0]?.dataset.messageId;
  }

  get lastMessageId() {
    return this.messageTargets[this.messageTargets.length - 1]?.dataset.messageId;
  }
}
