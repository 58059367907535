import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='file-select-view'
export default class extends Controller {
  static targets = ['input', 'fileList']

  displayFiles() {
    const files = this.inputTarget.files;
    const fileNames = Array.from(files).map(file => `<li>${file.name}</li>`).join('');
    this.fileListTarget.innerHTML = `<ul>${fileNames}</ul>`;
  }
}
