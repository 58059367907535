import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller='messages--read'
export default class extends Controller {
  static values = { reader: String };

  connect() {
    this.csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    this.observeElement()
  }

  observeElement() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.read(entry.target.dataset.messageId)
        }
      })
    }, {
      root: null,
      threshold: 1
    })

    observer.observe(this.element)
  }

  read(messageId) {
    if (messageId !== '') {
      fetch('/internals/messages/reads/' + messageId, {
        method: 'PUT',
        body: JSON.stringify({ reader: this.readerValue }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
      .then(response => response.text())
      .catch(error => console.error('Error:', error))
    }
  }
}
