import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='table-selectable'
export default class extends Controller {
  select(event) {
    const clickedRow = event.currentTarget.closest('tr');  // クリックされた行を取得
    const table = clickedRow.closest('table');  // クリックされた行が属するtableを取得
    const rows = table.querySelectorAll('tbody tr');  // そのtable内のすべてのtrを取得

    // すべての行から selected クラスを削除
    rows.forEach(row => {
      row.classList.remove('selected');
    });

    // クリックされた行に selected クラスを追加
    clickedRow.classList.add('selected');
  }
}
