import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="orders-selected-products"
export default class extends Controller {
  static targets = ["select", "list"]

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  selectProduct(event) {
    const selectedOption = event.target.selectedOptions[0]
    if (selectedOption.value !== '') {
      fetch('/internals/orders/order_products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        body: JSON.stringify({ product_id: selectedOption.value })
      })
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        selectedOption.remove()
      })
      .catch(error => console.error('Error:', error))
    }
  }

  removeProduct(event) {
    event.preventDefault()  // リンクのデフォルトの動作をキャンセル
    const productId = event.target.dataset.productId
    const productName = event.target.dataset.productName

    fetch(`/internals/orders/order_products/${productId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)

      // 削除された商品を select に追加
      const newOption = document.createElement('option')
      newOption.value = productId
      newOption.textContent = productName
      this.selectTarget.appendChild(newOption)
    })
    .catch(error => console.error('Error:', error))
  }
}
