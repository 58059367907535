// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import 'bootstrap/dist/js/bootstrap.min';
import 'admin-lte/src/ts/adminlte';
import '../src/controllers';

import { OverlayScrollbars } from 'overlayscrollbars';

// ページ読み込み後に実行するスクロール調整のコード
document.addEventListener("turbo:load", function() {
  if (window.location.hash) {
    const element = document.querySelector(window.location.hash);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 65,
        behavior: 'smooth'
      });
    }
  }
});

// OverlayScrollbars
document.addEventListener("turbo:load", function() {
  const sidebarWrapper = document.querySelector('.sidebar-wrapper');
  if (sidebarWrapper) {
    OverlayScrollbars(sidebarWrapper, {
      scrollbars: {
        theme: 'os-theme-light',
        autoHide: 'leave',
        clickScroll: true,
      },
    });
  }
});
