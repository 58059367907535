import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='product-designs-change-name'
export default class extends Controller {
  static targets = ["editForm", "name"];

  edit(event) {
    event.preventDefault();
    this.element.querySelector(".u-visually-hide").classList.remove("u-visually-hide");
    this.element.querySelector("span").classList.add("u-visually-hide");
  }
}
